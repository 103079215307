<!--
 * @Author: John
 * @Date: 2022-02-22 11:45:19
 * @LastEditTime: 2022-04-19 15:46:08
 * @LastEditors: John
 * @Description: 当前定投列表
 * @FilePath: \jjms_ui\src\views\trans\add.vue
 * @Copyright John
-->
<template>
    <div>
        <my-breadcrumb :data="breadcrumbData" />
        <div class="content-wrap">
            <add-trans-form :data="null" :isDialog="false" />
        </div>
    </div>
</template>
<script>
import myBreadcrumb from '@/components/my-breadcrumb.vue'
import addTransForm from '@/components/add-transaction.vue'

export default {
    name: 'tjdt',
    components: {
        myBreadcrumb,
        addTransForm
    },
    data() {
        return {
            breadcrumbData: [
                {title: '交易管理'},
                {title: '添加交易记录', url: '/main/trans-add'}
            ],
        }
    }
}
</script>
